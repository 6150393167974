/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  DeserializedSearchResult,
  SummaryLanguage,
  SearchError, 
  SearchResponse
} from "../views/search/types";
import { useConfigContext } from "./ConfigurationContext";
import {
  HistoryItem,
  addHistoryItem,
  deleteHistory,
  retrieveHistory,
} from "./history";
import { deserializeSearchResponse } from "../utils/deserializeSearchResponse";

import { ApiV2,  streamQueryV2 } from "@vectara/stream-query-client";
import { END_TAG, START_TAG } from "../utils/parseSnippet";
import { getRerankerConfig } from "../utils/getRerankerConfig";
import { User, useAuthenticationContext } from "./AuthenticationContext";
import { sendSearchRequest } from "./sendSearchRequest";
import { authenticateRequest } from "./authRequest";

interface SearchContextType {
  filterValue: string;
  setFilterValue: (source: string) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  modeValue: string;
  setModeValue: (value: string) => void;
  onSearch: ({
               value,
               filter,
               language,
               isPersistable,
               userOverride,
             }: {
    value?: string;
    filter?: string;
    language?: SummaryLanguage;
    isPersistable?: boolean;
    mode?: string;
    promptName?: string
    userOverride?: User
  }) => void;
  reset: () => void;
  isSearching: boolean;
  searchError: SearchError | undefined;
  searchResults: DeserializedSearchResult[] | undefined;
  searchTime: number;
  enableStreamQuery: boolean | undefined;
  isSummarizing: boolean;
  summarizationError: SearchError | undefined;
  summarizationResponse: string | undefined;
  summaryTime: number;
  factualConsistencyScore: number | undefined;
  language: SummaryLanguage;
  summaryNumResults: number;
  summaryNumSentences: number;
  summaryPromptName: string;
  summaryPromptText?: string;
  history: HistoryItem[];
  clearHistory: () => void;
  searchResultsRef: React.MutableRefObject<HTMLElement[] | null[]>;
  selectedSearchResultPosition: number | undefined;
  selectSearchResultAt: (position: number) => void;
  relatedContent: boolean;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const getQueryParam = (urlParams: URLSearchParams, key: string) => {
  const value = urlParams.get(key);
  if (value) return decodeURIComponent(value);
  return undefined;
};

type Props = {
  children: ReactNode;
};

let searchCount = 0;

export const SearchContextProvider = ({ children }: Props) => {
  const { isConfigLoaded, search, summary, setSummary, results, rerank, hybrid, filterBySource } =
    useConfigContext();
  
  const { user, setIsAuthenticated } = useAuthenticationContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState("");
  const [modeValue, setModeValue] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  // Language
  const [languageValue, setLanguageValue] = useState<SummaryLanguage>();

  // History
  const [history, setHistory] = useState<HistoryItem[]>([]);

  // Basic search
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState<SearchError | undefined>();
  const [searchResponse, setSearchResponse] = useState<SearchResponse | ApiV2.Query.SearchResult[]>();
  const [searchTime, setSearchTime] = useState<number>(0);

  // Summarization
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [summarizationError, setSummarizationError] = useState<
    SearchError | undefined
  >();
  const [summarizationResponse, setSummarizationResponse] =
    useState<string>();
  const [summaryTime, setSummaryTime] = useState<number>(0);
  const [factualConsistencyScore, setFactualConsistencyScore] = useState<number | undefined>();

  // Citation selection
  const searchResultsRef = useRef<HTMLElement[] | null[]>([]);
  const [selectedSearchResultPosition, setSelectedSearchResultPosition] =
    useState<number>();

  useEffect(() => {
    setHistory(retrieveHistory());
  }, []);

  // Use the browser back and forward buttons to traverse history
  // of searches, and bookmark or share the URL.
  useEffect(() => {
    // Search params are updated as part of calling onSearch, so we don't
    // want to trigger another search when the search params change if that
    // search is already in progress.

    if (!isConfigLoaded || isSearching) return;

    const urlParams = new URLSearchParams(searchParams);
    onSearch({
      // Set to an empty string to wipe out any existing search value.
      value: getQueryParam(urlParams, "query") ?? "",
      filter: getQueryParam(urlParams, "filter"),
      language: getQueryParam(urlParams, "language") as
        | SummaryLanguage
        | undefined,
      isPersistable: false,
      mode: getQueryParam(urlParams, "mode") ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigLoaded, searchParams]);

  const searchResults = deserializeSearchResponse(searchResponse);

  useEffect(() => {
    if (searchResults) {
      searchResultsRef.current = searchResultsRef.current.slice(
        0,
        searchResults.length
      );
    } else {
      searchResultsRef.current = [];
    }
  }, [searchResults]);

  const clearHistory = () => {
    setHistory([]);
    deleteHistory();
  };

  const selectSearchResultAt = (position: number) => {
    if (
      !searchResultsRef.current[position] ||
      selectedSearchResultPosition === position
    ) {
      // Reset selected position.
      setSelectedSearchResultPosition(undefined);
    } else {
      setSelectedSearchResultPosition(position);
      // Scroll to the selected search result.
      window.scrollTo({
        top: searchResultsRef.current[position]!.offsetTop - 78,
        behavior: "smooth",
      });
    }
  };

  const getLanguage = (): SummaryLanguage =>
    (languageValue ?? summary.defaultLanguage) as SummaryLanguage;

  const onSearch = async ({
    value = searchValue,
    filter = filterValue,
    language = getLanguage(),
    isPersistable = true,
    mode = modeValue,
    promptName = summary.summaryPromptName,
    userOverride = user
  }: {
    value?: string;
    filter?: string;
    language?: SummaryLanguage;
    isPersistable?: boolean;
    mode?: string;
    promptName?: string;
    userOverride?: User;
  }) => {
    const searchId = ++searchCount;

    setSearchValue(value);
    setFilterValue(filter);
    setLanguageValue(language);
    setModeValue(mode);
    setSummary({...summary, summaryPromptName: promptName})
   
    if (value?.trim()) {
      // Save to history.
      setHistory(addHistoryItem({ query: value, filter, language }, history));

      // Persist to URL, only if the search executes. This way the prior
      // search that was persisted remains in the URL if the search doesn't execute.
      if (isPersistable) {
        setSearchParams(
          new URLSearchParams(
            `?query=${encodeURIComponent(value)}&filter=${encodeURIComponent(
              filter
            )}&language=${encodeURIComponent(language)}&mode=${encodeURIComponent(mode)}`
          )
        );
      }

      setIsSearching(true);
      setIsSummarizing(true);
      setSelectedSearchResultPosition(undefined);
      const getRerankerNumResults = () => {
        if (filter) {
          return 100
        }
        else return rerank.numResults
      }
      
      // Check authentication first
      const authStatus = await authenticateRequest({auth:{email:userOverride?.email, token:userOverride?.authToken}});
      
      if (authStatus !== 200) {
        setIsAuthenticated(false)
        return;
      }
      
      if (search.enableStreamQuery) {
        try {
          const startTime = Date.now();
          const onStreamEvent = (event: ApiV2.StreamEvent) => {
            if (searchId === searchCount) {
              switch (event.type) {
                case "requestError":
                case "genericError":
                  setIsSearching(false);
                  setSearchResponse(undefined);
                  break;

                case "error":
                  if (!event.messages[0].includes("QRY__PARTIAL_RERANK: reranked") &&
                      !event.messages[0].includes("QRY__SMRY__EVAL_FAILURE")) {
                    setIsSearching(false);
                    setSearchResponse(undefined);
                  }
                  break;

                case "searchResults":
                  setSearchResponse(event.searchResults)
                  setIsSearching(false);
                  setSearchTime(Date.now() - startTime);

                  break;

                case "generationChunk":
                  setSummarizationError(undefined);
                  setSummarizationResponse(event.updatedText ?? undefined);
                  setFactualConsistencyScore(undefined)
                  break;

                case "factualConsistencyScore":
                  setFactualConsistencyScore(event.factualConsistencyScore > 0 ? event.factualConsistencyScore : undefined)
                  break;

                case "end":
                  setIsSummarizing(false);
                  setSummaryTime(Date.now() - startTime);
                  break;
              }
            }
          };

          const streamQueryConfig: ApiV2.StreamQueryConfig = {
            apiKey: search.proxyServerUrl ? ""  : search.apiKey!,
            customerId: search.proxyServerUrl ? ""  : search.customerId!,
            query: value,
            corpusKey: filterBySource.filterByCorpus && filter ? filter: search.corpusKey!,
            domain: search.proxyServerUrl,
            search: {
              limit: getRerankerNumResults(),
              offset: 0,
              metadataFilter: !filterBySource.filterByCorpus && filter ? `doc.source =  '${filter.toLowerCase()}'` : "",
              lexicalInterpolation:
                value.trim().split(" ").length > hybrid.numWords ? hybrid.lambdaLong : hybrid.lambdaShort,
              reranker: getRerankerConfig(rerank),
              contextConfiguration: {
                sentencesBefore: summary.summaryNumSentences,
                sentencesAfter: summary.summaryNumSentences,
                startTag: START_TAG,
                endTag: END_TAG
              }
            },
            generation: {
              generationPresetName: promptName,
              promptTemplate: summary.summaryPromptText,
              maxUsedSearchResults: summary.summaryNumResults,
              enableFactualConsistencyScore: true,
              responseLanguage: language
            },
            intelligentQueryRewriting: search.intelligentQueryRewriting
          };

          await streamQueryV2({ streamQueryConfig, onStreamEvent })

        } catch (error) {
          setIsSearching(false);
          setSearchError(error as SearchError);
          setSearchResponse(undefined);
          setIsSummarizing(false);
          setSummarizationError(error as SearchError);
          setSummarizationResponse(undefined);
        }
      }
      else {
        try {
          const startTime = Date.now();
          const response: SearchResponse = await sendSearchRequest({
            apiKey: search.apiKey!,
            query: value,
            corpusKey: filterBySource.filterByCorpus && filter ? filter: search.corpusKey!,
            endpoint: search.endpoint!,
            search: {
              limit: getRerankerNumResults(),
              offset: 0,
              metadataFilter: !filterBySource.filterByCorpus && filter ? `doc.source = '${filter.toLowerCase()}'` : "",
              lexicalInterpolation:
                value.trim().split(" ").length > hybrid.numWords ? hybrid.lambdaLong : hybrid.lambdaShort,
              reranker: getRerankerConfig(rerank),
              contextConfiguration: {
                sentencesBefore: summary.summaryNumSentences,
                sentencesAfter: summary.summaryNumSentences,
                startTag: START_TAG,
                endTag: END_TAG
              }
            },
            generation: {
              promptName: promptName,
              promptText: summary.summaryPromptText,
              maxUsedSearchResults: summary.summaryNumResults,
              enableFactualConsistencyScore: true,
              responseLanguage: language

            },
            intelligentQueryRewriting: search.intelligentQueryRewriting,
          })
          const totalTime = Date.now() - startTime;
          if (searchId === searchCount) {
            setSearchResponse(response.search_results)
            setIsSearching(false);
            setSearchTime(totalTime);
            setIsSummarizing(false);
            setSummarizationError(undefined);
            setSummarizationResponse(response.summary);
            setSummaryTime(totalTime);
            setFactualConsistencyScore(response.factual_consistency_score > 0 ? response.factual_consistency_score : undefined)
          }
        } catch (error) {
          console.log("Search error", error);
          setIsSearching(false);
          setSearchError(error as SearchError);
          setSearchResponse(undefined);
          setIsSummarizing(false);
          setSummarizationError(error as SearchError);
          setSummarizationResponse(undefined);
        }
      }
    }
  };

  const reset = () => {
    // Specifically don't reset language because that's more of a
    // user preference.
    onSearch({ value: "", filter: "", mode: "" });
  };

  return (
    <SearchContext.Provider
      value={{
        filterValue,
        setFilterValue,
        modeValue,
        setModeValue,
        searchValue,
        setSearchValue,
        onSearch,
        reset,
        isSearching,
        searchError,
        searchResults,
        searchTime,
        isSummarizing,
        summarizationError,
        summarizationResponse,
        summaryTime,
        enableStreamQuery: search.enableStreamQuery,
        factualConsistencyScore,
        language: getLanguage(),
        summaryNumResults: summary.summaryNumResults,
        summaryNumSentences: summary.summaryNumSentences,
        summaryPromptName: summary.summaryPromptName,
        summaryPromptText: summary.summaryPromptText,
        history,
        clearHistory,
        searchResultsRef,
        selectedSearchResultPosition,
        selectSearchResultAt,
        relatedContent: results.relatedContent,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};