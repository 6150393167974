export const authenticateRequest = async ({
  auth
}: {
  auth: { email?: string; token?: string }
}): Promise<number> => {
  if (!auth.email || !auth.token) {
    return 401; // Unauthorized if no credentials provided
  }

  try {
    const response = await fetch('/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth }),
    });
    
    return response.status;
  } catch (error) {
    console.error('Authentication error:', error);
    return 500; // Internal server error
  }
};
